<template>
  <transition appear appear-active-class="fade-enter-active">
    <div id="divCampaignDetailWrapper" v-if="campaign" class="campaign-wrapper">
      <div v-if="campaign.videoUrl">
        <a :href="campaign.coverImageLink" class="media-link">
          <BrandVimeoPlayer
            ref="vimeoPlayer"
            :id="campaign.videoUrl"
            :data="{ embedVideo: campaign.videoUrl }"
          ></BrandVimeoPlayer>
        </a>
      </div>
      <a v-else :href="campaign.coverImageLink" class="media-link">
        <BrandMediaContentBox
          :mediaSrc="campaign.largeImageURL"
          alt="campaign-image"
          :aspectRatios="media.MEDIA_ASPECT_RATIOS.by_16_9"
          :lazyload="false"
        />
      </a>

      <div class="button-wrapper">
        <BrandButton
          class="campaign-wrapper-image-join"
          v-if="campaign.link"
          :size="sizes.xSmall"
          as="a"
          :target="campaign.link"
        >
          <VueText color="white-100" sizeLevel="4" weightLevel="3">HEMEN KATIL</VueText>
        </BrandButton>
      </div>

      <div class="campaign-wrapper-description">
        <div class="description-wrapper">
          <div class="description-title">
            <VueText sizeLevel="6" weightLevel="3">{{ campaign.title }}</VueText>
          </div>
          <div class="description-content" v-html="htmlText"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox';
import { Campaign } from '@/services/Api/index';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';

export default {
  name: 'CampaignDetail',
  components: {
    VueText,
    BrandButton,
    BrandMediaContentBox,
    BrandVimeoPlayer,
  },
  data() {
    return {
      campaign: {},
      htmlText: '',
    };
  },

  beforeMount() {
    Campaign.getCampaignDetail(this.$route.params.id).then(res => {
      if (res.data && res.data.Data) {
        this.campaign = res.data.Data.campaign.campaign;
        this.htmlText = res.data.Data.campaign.htmlText;
      }
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    media() {
      return MEDIA_CONSTANTS;
    },
  },
};
</script>
<style lang="scss">
@import '@/styles/_animations.scss';
.campaign-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: palette-space-level(20);
  .feed-media {
    position: relative;
    display: flex;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    align-items: center;
    overflow: hidden;

    &:before {
      content: '';
      display: block;
    }

    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .campaign-wrapper-image {
    position: relative;
    &-back {
      position: absolute;
      height: 30px;
      width: 35px;
      left: palette-space-level(20);
      top: palette-space-level(20);
    }
  }
  .campaign-wrapper-description {
    .description-wrapper {
      padding: palette-space-level(20);
      .description-title {
        border-bottom: 1px solid palette-color-level('grey', 20);
        padding-bottom: palette-space-level(15);
      }
      .description-content {
        padding-top: palette-space-level(15);
      }
    }
  }
}
.button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: palette-space-level(20);
}
.campaign-wrapper-image-join {
  max-width: 130px;
  max-height: 35px;
}
.media-link {
  display: block;
  text-decoration: none;
  cursor: pointer;
}
</style>
